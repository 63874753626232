import { graphql } from "gatsby";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";
import {
  BulletTick,
  Button,
  Column,
  CurvedSectionDivider,
  FormattedText,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  Line,
  MaxWidth,
  PageMaxWidth,
  Section,
  SectionIntegrations,
  SectionQuotes,
} from "../components";
import Text from "../components/Text";
import Visibility from "../components/Visibility";
import { Color, Dimen } from "../constants";
import { fluidImage } from "../prismic-tools";

type Props = {
  data: GatsbyTypes.IntegrationPagesQuery;
};

export const query = graphql`
  query IntegrationPages($uid: String!) {
    prismicIntegrations(uid: { eq: $uid }) {
      data {
        hero_title
        hero_button_text
        hero_description {
          raw
        }
        hero_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        hero_lists {
          list_text
        }
        quotes {
          quote_content {
            raw
          }
          quote_author_image {
            alt
            dimensions {
              height
              width
            }
            url
          }
          quote_author_name
          quote_author_role
          quote_company_logo {
            alt
            dimensions {
              height
              width
            }
            url
          }
          quote_title
        }

        solutions_title
        solutions {
          solution_text
          solution_icon
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;
export default function IntegrationPage({ data }: Props) {
  const integration = data.prismicIntegrations?.data;

  if (!integration) throw new Error("Integration page missing");

  const solutionsColors = {
    icon_green_clipboard_tick: "green1",
    icon_yellow_paper_arrow: "yellow1",
    icon_blue_clipboard: "lightBlue1",
    icon_purple_clipboard: "violet1",
    icon_red_clipboard_tick: "secondaryOrange1",
    icon_green_clipboard: "secondaryGreen1",
  };
  return (
    <Layout>
      <HelmetSeo {...integration} />
      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <Grid alignColumns="center" reverseColumns>
            <Column size={6}>
              <MaxWidth width={528}>
                <Img fluid={fluidImage(integration.hero_image, 528)} />
              </MaxWidth>
            </Column>
            <Column size={1} />
            <Column size={5}>
              <Text typeface="h1" bold as="h1">
                {integration.hero_title}
              </Text>
              <Gutter size={2} />
              <HorizontalAlign align="flex-start">
                <Button
                  colorTheme="orange"
                  label={integration.hero_button_text}
                  to="/contact"
                />
              </HorizontalAlign>
            </Column>
          </Grid>
        </PageMaxWidth>
      </Section>
      <Gutter size={5} md={1} />
      <Section>
        <PageMaxWidth>
          <Grid justifyColumns="space-between">
            <Column size={5}>
              <Gutter />
              <FormattedText>
                <RichText render={integration.hero_description?.raw} />
              </FormattedText>
              <Gutter />
            </Column>
            <Column size={6}>
              <StyledBulletCard>
                <Gutter />
                {integration.hero_lists?.map(
                  (hl) =>
                    hl && (
                      <BulletTick key={hl.list_text}>
                        <Text>{hl.list_text}</Text>
                      </BulletTick>
                    )
                )}
              </StyledBulletCard>
            </Column>
          </Grid>
        </PageMaxWidth>
      </Section>

      <Gutter size={4} />

      <CurvedSectionDivider
        frontColor="newDark2"
        position="top"
        backColor="light1"
      />
      <Section background="dark">
        <PageMaxWidth>
          <Gutter size={2} />
          <SectionQuotes quotes={integration.quotes} buttonLabel={null} />
          <Gutter size={4} md={1} />
          <Line color="orange1" />
          <Gutter size={4} md={2} />
          <Text typeface="h3" bold color="light1" align="center" as="h2">
            {integration.solutions_title}
          </Text>
          <Gutter size={3} />
          <Grid justifyColumns="center">
            {integration.solutions?.map(
              (solution, i, arr) =>
                solution &&
                solution.solution_icon && (
                  <Column size={4}>
                    <StyledIntegrationContainer>
                      <StyledIcon
                        $last={i === (arr.length || 0) - 1}
                        $endOfRow={i === 2}
                      >
                        <Text
                          bold
                          typeface="h5"
                          color={
                            solutionsColors[
                              solution.solution_icon as keyof typeof solutionsColors
                            ] as keyof typeof Color
                          }
                          align="center"
                        >
                          {solution.solution_text}
                        </Text>

                        <Gutter />
                        <HorizontalAlign align="center">
                          <StyledCircleOutline
                            $color={
                              solutionsColors[
                                solution.solution_icon as keyof typeof solutionsColors
                              ] as keyof typeof Color
                            }
                          >
                            <div>
                              <img
                                src={require(`../../img/templates/integration_page/${solution.solution_icon}.svg`)}
                              />
                            </div>
                          </StyledCircleOutline>
                        </HorizontalAlign>
                      </StyledIcon>
                    </StyledIntegrationContainer>
                    <Visibility hideBelow={"md"}>
                      <Gutter />
                    </Visibility>
                  </Column>
                )
            )}
          </Grid>
          <Gutter size={3} />
        </PageMaxWidth>
      </Section>
      <CurvedSectionDivider
        frontColor="newDark2"
        position="bottom"
        backColor="light1"
      />
      <Gutter size={2} md={0} />
      <SectionIntegrations />
      <Gutter size={2} md={0} />
    </Layout>
  );
}

const StyledBulletCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 460px;
  min-height: 214px;
  box-shadow: 0px 12px 56px rgba(19, 19, 31, 0.05);
  border-radius: 9px;
  padding-right: 20px;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    width: 100%;
  }
`;

const StyledIntegrationContainer = styled.div`
  margin-bottom: 10px;
`;

const StyledCircleOutline = styled.div<{ $color: keyof typeof Color }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 2px solid ${(p) => Color[p.$color]};
  border-radius: 50%;
  box-shadow: 0px 6px 17px #000000;
  @media (max-width: ${Dimen.breakpointDownMd}px) {
    width: 70px;
    height: 70px;
  }
`;

const StyledIcon = styled("div")<{ $last: boolean; $endOfRow: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${(p) =>
    !p.$last &&
    !p.$endOfRow &&
    `
    &::after {
      content: '';
      width: 1px;
      height: 115px;
      background-color: #7c7c7c;
      position: absolute;
      top: 60px;
      right: -${Dimen.gutterWidth / 2}px; 
    }
  `}

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    flex-direction: row-reverse;
    max-width: 202px;
    margin: 0 auto;
    padding-bottom: ${Dimen.gutterWidth}px;
    p {
      flex: 1;
    }
    img {
      width: 30px;
    }
    &::after {
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: 1px;
    }

    ${(p) =>
      p.$endOfRow &&
      !p.$last &&
      `
      &::after {
      content: '';
      background-color: #7c7c7c;
      position: absolute;
    `}
  }
`;
